import { BehaviorSubject, Observable, of } from 'rxjs';

import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '@app/models/site';
import { catchError, finalize, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { ISiteMapDetails } from '@app/models/ISiteMapDetails';
import { ServiceHelper } from './service.helper';
import { PostCodeIO } from '@app/models/postcode-io';
import { ApiExtendedService } from './root/api-extended.service';
import { OpeningDaysAndTime } from '@app/models/opening-days-and-time';
import { IDayOfWeek } from '@app/models/IDayOfWeek.enum';
import { IDisplayedOpeningHours } from '@app/models/site/IDisplayedOpeningHours';
import { OrderOccasion } from '@app/models/order-occasion';
import { SiteOccasionOpeningHours } from '@app/models/site/SiteOccasionOpeningHours';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends ApiExtendedService {
  public currentSite$: Observable<Site>;
  public sites: BehaviorSubject<Site[]>;
  public onBusy = new BehaviorSubject<boolean>(false);
  public currentSite: BehaviorSubject<Site>;

  private _detailedSites: BehaviorSubject<ISiteMapDetails[]>;
  private _sitesRequest$: Observable<Site[]> | null = null;

  constructor(
    private _serviceHelper: ServiceHelper
  ) {
    super();
    this.sites = new BehaviorSubject<Site[]>(null);
    this.currentSite = new BehaviorSubject<Site>(null);
    this.currentSite$ = this.currentSite.asObservable();
    this._detailedSites = new BehaviorSubject<ISiteMapDetails[]>(null);
  }

  /**
   * Get the sites for the tenant
   */
  public getSites(): Observable<Site[]> {
    this.onBusy.next(true);
    return this.sites.value ? this.sites.asObservable() : this.getSitesFromApi();
  }

  /**
   * Gets the site by postal code from the API
   */
  public getSitesByPostCode(postCode: string): Observable<Site[]> {
    this.onBusy.next(true);

    return this.getResource<Site[]>('sites', new HttpParams().set('PostCode', postCode))
        .pipe(
            catchError((error: HttpErrorResponse) => {
              this.trackError(error);
              return of([]);
            }),
            finalize(() => this.onBusy.next(false))
        ) as Observable<Site[]>;
  }

  /**
   * Sets the current site
   */
  public setCurrentSite(site: Site): void {
    this.currentSite.next(site);
  }

  /**
   * Sets the current site by site ID
   */
  public setCurrentSiteBySiteId(siteId: string): void {
    const getMatchingSite = (sites: Site[] | null) => sites?.find((site: Site) => site.Id.toLowerCase() === siteId.toLowerCase());

    const site = getMatchingSite(this.sites.getValue());

    if (site) {
      this.setCurrentSite(site);
      return;
    }

    this.getSites()
        .pipe(
            map((sites: Site[]) => getMatchingSite(sites)),
            takeUntil(this.destroy$)
        )
        .subscribe((x: Site) => this.setCurrentSite(x));
  }

  /**
   * Gets the detailed sites
   */
  public get detailedSites$(): Observable<ISiteMapDetails[]> {
    if (!this._detailedSites.value) {
      this._detailedSites.next([]);

      this.getSites()
          .pipe(takeUntil(this.destroy$))
          .subscribe((async (value: Site[]) => {
            this._detailedSites.next(await this.getDetailedSites(value));
          }));
    }

    return this._detailedSites.asObservable();
  }

  /**
   * Gets the sites from the API
   */
  private getSitesFromApi(): Observable<Site[]> {
    if (!this._sitesRequest$) {
      this.onBusy.next(true);

      this._sitesRequest$ = this.getResource<Site[]>('sites').pipe(
          tap((response: Site[]) => {
            this.sites.next(response);
            this.onBusy.next(false);
          }),
          catchError((error: HttpErrorResponse) => {
            this.trackError(error);
            this.onBusy.next(false);
            return of([]);
          }),
          shareReplay(1),
          finalize(() => {
            this._sitesRequest$ = null;
          })
      );
    }

    return this._sitesRequest$;
  }

  /**
   * maps all sites to the ISiteMapDetails interface
   */
  private async getDetailedSites(sites: Site[]): Promise<ISiteMapDetails[]> {
    const siteMapDetailsPromises: Promise<ISiteMapDetails>[] = sites
        .filter((x: Site) => x.Address.Postcode)
        .map((x: Site) => this.createSiteMapDetails(x));
    return await Promise.all(siteMapDetailsPromises);
  }

  /**
   * creates a site map details object from a site
   * @param site
   */
  private async createSiteMapDetails(site: Site): Promise<ISiteMapDetails> {
    const location = site.Address.Longitude !== 0 && site.Address.Latitude
      ? { latitude: site.Address.Latitude, longitude: site.Address.Longitude }
      : await this.getLatLongFromPostcode(site.Address.Postcode);

    return {
      address: site.Address,
      id: site.Id,
      location,
      name: site.Name,
      occasionsSupported: site.OccasionsSupported,
      openingHours: this._serviceHelper.getOpeningDatesAndTimes(site.OpeningHours),
      phone: site.Phone,
      selected: false,
      specialOpeningHoursMessages: site.SpecialOpeningHoursMessages,
      occasionOpeningHours: this.getOccasionOpeningHours(site)
    };
  }

  /**
   * gets the occasion opening hours for a site
   * @param site
   */
  private getOccasionOpeningHours(site: Site): IDisplayedOpeningHours[] {
    const todaysDate: IDayOfWeek = this.getTodaysDayAdjustedForDayBoundary();

    const getOccasionDatesAndTimes = (occasion: OrderOccasion) => this._serviceHelper.getOpeningDatesAndTimes(
        site.OccasionOpeningHours?.find((x: SiteOccasionOpeningHours) => x.Occasion === occasion)?.OpeningHours ?? []
    );

    const defaultDaysAndTimes: OpeningDaysAndTime[] = this._serviceHelper.getOpeningDatesAndTimes(site.OpeningHours);
    const deliveryDaysAndTimes: OpeningDaysAndTime[] = getOccasionDatesAndTimes(OrderOccasion.Delivery);
    const collectionDaysAndTimes: OpeningDaysAndTime[] = getOccasionDatesAndTimes(OrderOccasion.Collection);
    const dineInDaysAndTimes: OpeningDaysAndTime[] = getOccasionDatesAndTimes(OrderOccasion.DineIn);

    const getSingleDay = (days: OpeningDaysAndTime[], day: IDayOfWeek) => (days ?? []).find((x: OpeningDaysAndTime) => x.Day === day);
    const hasOverrides = (occasion: OrderOccasion) => site.OccasionOpeningHours.some((x: SiteOccasionOpeningHours) => x.Occasion === occasion);
    const isEmpty = (x?: OpeningDaysAndTime) => !x || x.times.length === 0;

    const allDays: IDisplayedOpeningHours[] = Object.keys(IDayOfWeek)
        .map((day: IDayOfWeek) => {
          const defaultHours: OpeningDaysAndTime = getSingleDay(defaultDaysAndTimes, day);
          const delivery: OpeningDaysAndTime = getSingleDay(deliveryDaysAndTimes, day);
          const collection: OpeningDaysAndTime = getSingleDay(collectionDaysAndTimes, day);
          const dineIn: OpeningDaysAndTime = getSingleDay(dineInDaysAndTimes, day);
          const isClosed: boolean = isEmpty(defaultHours) && isEmpty(delivery) && isEmpty(collection) && isEmpty(dineIn);

          return {
            day,
            isToday: todaysDate === day,
            isClosed,
            ...(defaultHours && { defaultHours: defaultHours.times }),
            ...(hasOverrides(OrderOccasion.Delivery) && { delivery: delivery.times }),
            ...(hasOverrides(OrderOccasion.Collection) && { collection: collection.times }),
            ...(hasOverrides(OrderOccasion.DineIn) && { dineIn: dineIn.times })
          } as IDisplayedOpeningHours;
        });

    // Slice the array based on today's index and reorder it
    const index = allDays.findIndex((x: IDisplayedOpeningHours) => x.isToday);
    return [...allDays.slice(index), ...allDays.slice(0, index)];
  }

  /**
  * returns latitude and longitude coordinates from a postcode
  * @param postcode - the postcode
  */
  private getLatLongFromPostcode(postcode: string): Promise<{ latitude: number; longitude: number }> {
    return fetch(`https://api.postcodes.io/postcodes/${postcode}`)
        .then((response) => response.json())
        .then((result: PostCodeIO) => ({
          latitude: result.result.latitude,
          longitude: result.result.longitude
        }));
  }

  /**
   * Converts a time string 'HH:mm' to a comparable number for easier time comparison.
   * Handles the special case where the time is after midnight but before 06:30 (i.e., the "next day").
   * @param {string} time - The time in 'HH:mm' format.
   * @returns {number} The comparable number (e.g., 0630 -> 630, 19:45 -> 1945, 02:00 -> 2600).
   */
  private convertTimeToComparableNumber(time: string, handleNextDayOffset: boolean = true): number {
    const [hours, minutes] = time.split(':').map(Number);

    // Treat times between 00:00 and 06:29 as part of the "next day" (add 24 hours)
    if (handleNextDayOffset && (hours < 6 || (hours === 6 && minutes < 30))) {
      return (hours + 24) * 100 + minutes;
    }

    // For times from 06:30 onward, treat them as part of the current day
    return hours * 100 + minutes;
  }

  /**
   * Maps the current day of the week to the correct day, adjusting for the 06:30 day boundary.
   * @returns {string} The name of today's day, adjusted for the 06:30 day boundary.
   */
  private getTodaysDayAdjustedForDayBoundary(): IDayOfWeek {
    const mapDayOfWeek: IDayOfWeek[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as IDayOfWeek[];

    // Get the current time in 'HH:mm' format
    const currentTime = this.getCurrentTime();

    // Convert the current time to a comparable number
    const currentComparableTime = this.convertTimeToComparableNumber(currentTime, false);

    // If the current time is before 06:30, consider it the previous day
    if (currentComparableTime < 630) {
      const previousDayIndex = (new Date().getDay() - 1 + 7) % 7; // Adjust for negative day index (Sunday to Saturday)
      return mapDayOfWeek[previousDayIndex];
    }

    // Otherwise, it's the current day
    return mapDayOfWeek[new Date().getDay()];
  }

  /**
   * Gets the current time in 'HH:mm' format.
   * @returns {string} The current time.
   */
  private getCurrentTime(): string {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
}
